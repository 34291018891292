<style lang="scss" scoped>
</style>
<template>
  <div class="">
    <el-form
      :model="state.ruleForm"
      :rules="state.rules"
      ref="ruleForm"
      label-width="100px"
      class="setPassword"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="推送內容" prop="text">
            <el-input
              class="textarea"
              :rows="4"
              type="textarea"
              v-model="state.ruleForm.text"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="setPass()">提 交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, reactive, getCurrentInstance, ref } from "vue";
export default defineComponent({
  setup() {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message } = proxy;
    const state = reactive({
      ruleForm: {
        text: "",
      },
      rules: {
        text: [{ required: true, message: "請輸入推送內容", trigger: "blur" }],
      },
    });

    const setPass = async () => {
      if (!state.ruleForm.text) return;
      const res = await $http.pushAds({ text: state.ruleForm.text });
      if (res.status !== 200) return;
      $message.success("推送成功");
    };

    return { state, setPass };
  },
});
</script>
